import React from 'react'
import { Grid, GridProps } from '@material-ui/core'

export interface ColumnsProps {
    children: React.ReactElement[]
    columns: 2 | 3
}

export const Columns: React.FC<ColumnsProps> = ({ columns, children }) => {
    return (
        <Grid container data-testid='Columns' spacing={5}>
            {children?.map((child: React.ReactElement, index) => (
                <Grid
                    item
                    key={index}
                    xs={12}
                    md={(12 / columns) as GridProps['xs']}
                >
                    {child}
                </Grid>
            ))}
        </Grid>
    )
}
