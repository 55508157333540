import { Grid, Hidden } from '@material-ui/core'
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#F7F8FB',
            color: '#3A3160',
            border: '0px'
        },
        body: {
            fontSize: 14
        }
    })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#F0F3FD',
                color: '#766F90'
            }
        }
    })
)(TableRow)

const useStyles = makeStyles({
    table: {}
})

function createData(column1: string, column2: string) {
    return { column1, column2 }
}

const populateTableData = (page: string) => {
    switch (page) {
        case 'smart_invest':
            return [
                createData('Between the ages of 18 and 69', 'A U.S. citizen'),
                createData(
                    'Living in the Republic of Ireland',
                    'A politically exposed person (“PEP”)'
                ),
                createData(
                    'A tax resident only in the Republic of Ireland',
                    'A relative or a close associate of a PEP'
                )
            ]
        default:
            return [
                createData('Under age 30', '15%'),
                createData('30 to 39', '20%'),
                createData('40 to 49', '25%'),
                createData('50 to 54', '30%'),
                createData('55 to 59', '35%'),
                createData('60 and above', '40%')
            ]
    }
}

export interface TextWithTableProps {
    title: string
    text: string
    codeName: string
}

export const TextWithTable: React.FC<TextWithTableProps> = ({
    text,
    title,
    codeName
}) => {
    const classes = useStyles()

    return (
        <section>
            <Grid data-testid='TextWithTable' container spacing={6}>
                <Grid item xs={12} md={6}>
                    <Text box={{ mb: 7 }} label={title} variant='h4' component='h1' />
                    <Text
                        box={{ color: '#766F90' }}
                        html
                        label={text}
                        variant='subtitle1'
                        component='p'
                    />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={1} />
                </Hidden>
                <Grid item xs={12} md={5}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label='simple table'
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        style={{ fontSize: '18px' }}
                                    >
                                        {codeName === 'smart_invest'
                                            ? 'You Must Be'
                                            : 'Age'}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{ fontSize: '18px' }}
                                        align='right'
                                    >
                                        {codeName === 'smart_invest'
                                            ? 'You Must Not Be'
                                            : 'Percentage of earnings you can contribute'}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {populateTableData(codeName).map(row => (
                                    <StyledTableRow key={row.column1}>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            style={{
                                                color: '#766F90',
                                                border: '0px'
                                            }}
                                        >
                                            <Text label={row.column1} />
                                        </TableCell>

                                        <TableCell
                                            align='right'
                                            style={{
                                                color: '#766F90',
                                                border: '0px'
                                            }}
                                        >
                                            <Text label={row.column2} />
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </section>
    )
}
