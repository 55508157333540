import React from 'react'
import { Columns } from './Columns'

export interface ThreeColumnsProps {
    children: React.ReactElement[]
}

export const ThreeColumns: React.FC<ThreeColumnsProps> = ({ children }) => {
    return <Columns columns={3}>{children}</Columns>
}
