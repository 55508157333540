import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import {
    CampaignJumbotron,
    CampaignJumbotronProps
} from 'common/components/organisms/CampaignJumbotron'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import {
    Information,
    InformationProps
} from 'common/components/organisms/Information'
import {
    LatestNews,
    LatestNewsProps
} from 'common/components/organisms/LatestNews'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { Notice, NoticeProps } from 'common/components/organisms/Notice'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'
import {
    TextWithTable,
    TextWithTableProps
} from 'common/components/organisms/TextWithTable'
import {
    TimelineSteps,
    TimelineStepsProps
} from 'common/components/organisms/TimelineSteps'
import { useDesktop } from 'common/hooks/useDesktop'
import { CampaignFeature } from '@irishlife/ilgroupdesignsystem.organisms.campaign-feature'
import { getCampaignFeatureProps } from 'common/utils/geCampaignFeatureProps'
import React from 'react'

export interface CampaignLandingProps {
    // jumbotron: JumbotronProps
    benefits: BenefitsProps
    layout: LayoutProps
    secondCallToAction: CampaignFeatureProps
    timeline: TimelineStepsProps
    jumbotron: CampaignJumbotronProps
    textWithTable: TextWithTableProps
    featureList: FeatureListProps
    featureList2: FeatureListProps
    alerts: AlertsProps
    information: InformationProps
    notices: NoticeProps[]
    resources: ResourcesProps
    relatedArticles: LatestNewsProps
}

export const CampaignLanding: React.FC<CampaignLandingProps> = ({
    layout,
    secondCallToAction,
    timeline,
    jumbotron,
    textWithTable,
    featureList2,
    featureList,
    alerts,
    information,
    relatedArticles,
    notices = [],
    resources,
    benefits
}) => {

    const desktop = useDesktop()
    const campaignFeatureProps = getCampaignFeatureProps(secondCallToAction)

    return (
        <Layout {...layout}>
            <CampaignJumbotron {...jumbotron} />
            <Spacer variant={desktop ? 'sm' : 'md'} />
            <Wrapper style={{ background: '#f7f7fb' }}>
                <Spacer variant={desktop ? 'sm' : 'md'} />

                {timeline.title && (
                    <>
                        <Spacer variant={!desktop ? 'md' : 'lg'} />
                        <TimelineSteps {...timeline} />
                    </>
                )}
                {featureList.title && (
                    <FeatureList variant='top' {...featureList} />
                )}
                {benefits.title && (
                    <Benefits {...benefits} title={benefits?.title} />
                )}

                {relatedArticles.label && (
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <LatestNews {...relatedArticles} />
                    </>
                )}

                {textWithTable.title && (
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <TextWithTable {...textWithTable} />
                    </>
                )}

                {featureList2.title && (
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <FeatureList variant='top' {...featureList2} />
                    </>
                )}
                {notices && (
                    <>
                        {notices.map((notice, index) => (
                            <>
                                <Spacer variant={desktop ? 'lg' : 'md'} />
                                <Notice {...notice} key={index} />
                            </>
                        ))}
                    </>
                )}
                {resources.title && (
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <Resources {...resources} />
                    </>
                )}
                {information.title && (
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <Information {...information} />
                    </>
                )}
                {alerts && (
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <Alerts {...alerts} />
                    </>
                )}
            </Wrapper>
            {secondCallToAction.title && (
                <CampaignFeature {...campaignFeatureProps} />
            )}
        </Layout>
    )
}
