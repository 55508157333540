import { Grid } from '@material-ui/core'
import { ThreeColumns, TwoColumns } from 'common/components/molecules/columns'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface InformationProps {
    title: string
    text: string[]
}

export const Information: React.FC<InformationProps> = ({
    title,
    text = []
}) => {
    const desktop = useDesktop()
    if (text.length === 1)
        return (
            <>
                <Text label={title} variant={desktop ? 'h4' : 'h2'} />
                <Grid data-testid='Information' container>
                    <Grid item xs={12} md={8}>
                        <Text
                            html
                            key={text[0]}
                            label={text[0]}
                            style={{ color: '#766F90' }}
                            variant='body2'
                        />
                    </Grid>
                </Grid>
                <Spacer />
            </>
        )

    const ColumnsComponent = text.length === 2 ? TwoColumns : ThreeColumns

    return (
        <>
            <Grid data-testid='Information' item xs={12} md={6}>
                <Text label={title} variant={desktop ? 'h4' : 'h2'} />
            </Grid>
            <ColumnsComponent>
                {text.map(column => (
                    <Text html key={column} label={column} variant='body2' />
                ))}
            </ColumnsComponent>
            <Spacer variant={'lg'} />
        </>
    )
}
