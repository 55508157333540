import { FeatureListProps } from 'common/components/organisms/FeatureList'
import {
    getJumbotronData,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { getResourcesData } from 'common/components/organisms/Resources'
import { CampaignLanding } from 'common/components/templates/CampaignLanding/CampaignLanding'
import { getArticleData } from 'common/utils/getArticleData'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CampaignLandingPageProps {
    pageContext: any
}

export const CampaignLandingPage: React.FC<CampaignLandingPageProps> = ({
    pageContext
}) => {
    return <CampaignLanding {...getCampaignLandingData(pageContext)} />
}

export default CampaignLandingPage
export const getCampaignLandingData = (data: any) => ({
    layout: {
        ...getLayoutData(data),
        jumbotronVariant: 'campaign' as JumbotronProps['variant']
    },
    jumbotron: {
        ...getJumbotronData(data),
        image: data.campaign_jumbotron_image,
        variant: 'campaign' as JumbotronProps['variant']
    },
    secondCallToAction: {
        title: data.title,
        text: data.text,
        image: data.image,
        callToAction: {
            analyticsId: data.call_to_action__analytics_id,
            label: data.cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            text: data.call_to_action__text,
            title: data.call_to_action__title,
            url: data.cta_url,
            variant: data.call_to_action__variant
        }
    },
    featureList: {
        text: data.featurelist__text,
        cta: data.featurelist__cta,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant,
            noSpacer: true
        },
        image: data.featurelist__image,
        title: data.featurelist__title,
        subtitle: data.featurelist__subtitle,
        features: data.featurelist__features,
        variant: data.featurelist2__title
            ? 'top'
            : (undefined as FeatureListProps['variant'])
    },
    latestNews: { list: (data.blogPosts || []).map(getArticleData) },

    featureList2: {
        text: data.featurelist2__text,
        cta: data.featurelist2__cta,
        callToAction: {
            analyticsId: data.featurelist2__cta_analyticsid,
            label: data.featurelist2__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist2__cta_icon,
            url: data.featurelist2__cta_url,
            variant: data.featurelist2__cta_variant,
            noSpacer: true
        },
        image: data.featurelist2__image,
        title: data.featurelist2__title,
        subtitle: data.featurelist2__subtitle,
        features: data.featurelist2__features
    },
    timeline: {
        text: data.step_text,
        title: data.step_title,
        heading: data.heading,
        steps: data.timeline__steps
    },
    accordionItems: {
        accordionItems: data.accordion
    },
    textWithTable: {
        text: data.table_text,
        title: data.table_title,
        codeName: data.codename
    },
    alerts: {
        alerts: data.alerts__alerts?.map((alert: any) => alert.text)
    },
    notices: (data.notices || []).map((notice: any) => ({
        title: notice.title,
        text: notice.text,
        subTitle: notice.subtitle,
        variant: notice.variant,
        callToAction: {
            label: notice.call_to_action__label,
            [`${notice.call_to_action__iconlocation}Icon`]: notice.call_to_action__icon,
            text: notice.call_to_action__text,
            title: notice.call_to_action__title,
            url: notice.call_to_action__url,
            variant: notice.call_to_action__variant
        }
    })),
    information: {
        title: data.information__title,
        text: (data.information__paragraphs || []).map(
            (paragraph: { text: string }) => paragraph.text
        )
    },
    resources: getResourcesData(data),
    benefits: {
        benefits: (data.threecards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title,
        ctaLabel: data.threecards__cta_label,
        ctaUrl: data.threecards__cta_url,
        analyticsId: data.threecards__ctaanalyticsid
    },
    relatedArticles: {
        label: data.related_articles_label,
        list: (data.related_articles || []).map(getArticleData)
    }
})
